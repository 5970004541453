var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('MainSectionPanel',{attrs:{"requirements":_vm.requirements,"loading":_vm.loading,"loadingMore":_vm.loadingMore,"panels":_vm.getDocumentPanels,"guestAllowed":""},on:{"loadMore":_vm.loadMore,"changePanels":_vm.onMainPanelChange},scopedSlots:_vm._u([{key:"mainContent",fn:function(ref){
var requirements = ref.requirements;
var sectionNumber = ref.sectionNumber;
return [_c('MainRequirementPanel',{attrs:{"requirements":requirements,"sectionNumber":sectionNumber,"panels":_vm.getDocumentSubPanels},on:{"changePanels":_vm.onSubPanelChange},scopedSlots:_vm._u([{key:"singleMain",fn:function(ref){
var requirement = ref.requirement;
var removedBorder = ref.removedBorder;
return [_c('DocumentRequirementRow',{staticClass:"p-0 document-row",attrs:{"requirement":requirement,"removedBorder":removedBorder,"years":_vm.years}})]}},{key:"mainRow",fn:function(ref){
var mainRequirement = ref.mainRequirement;
return [(
              (mainRequirement.documentContainers && mainRequirement.documentContainers.length) ||
                mainRequirement.inheritedContainer
            )?_c('DocumentRequirementRow',{staticClass:"document-row",attrs:{"requirement":mainRequirement,"years":_vm.years,"isMain":""}}):_vm._e()]}},{key:"containerContent",fn:function(ref){
            var requirement = ref.requirement;
return [_c('DocumentRequirementRow',{staticClass:"document-row",attrs:{"requirement":requirement,"years":_vm.years}})]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }