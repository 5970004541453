import { i18n } from '@/i18n';
import { DocumentExpiry } from '@/model/enums/document-expiry';

export const documentExpiry = [
  {
    title: i18n.t('documents.detailed-view.expiry-filter.this-month'),
    value: DocumentExpiry.THIS_MONTH,
  },
  {
    title: i18n.t('documents.detailed-view.expiry-filter.next-three-month'),
    value: DocumentExpiry.NEXT_THREE_MONTHS,
  },
  {
    title: i18n.t('documents.detailed-view.expiry-filter.this-year'),
    value: DocumentExpiry.THIS_YEAR,
  },
  {
    title: i18n.t('documents.detailed-view.expiry-filter.next-three-years'),
    value: DocumentExpiry.NEXT_THREE_YEARS,
  },
];
