










































import Vue from 'vue';
import DetailedViewBase from '@/components/DetailedViewPopup/DetailedViewBase.vue';
import Navigation from '@/components/Dashboard/DetailedView/Navigation.vue';
import DocumentStoreDocuments from '@/components/DocumentStore/DetailedView/Documents.vue';
import DocumentStoreComments from '@/components/DocumentStore/DetailedView/Comments.vue';
import DocumentStoreChangelog from '@/components/DocumentStore/DetailedView/Changelog.vue';
import DocumentStoreBasicInfiormations from '@/components/DocumentStore/DetailedView/BasicInformation.vue';
import ActionButton from '@/components/DetailedViewPopup/ActionButton.vue';
import { menus } from '@/constants/menu/documents-detailed-view-menus';
import DocumentContainer from '@/model/document-container';
import DocumentContainerRequest from '@/model/request/document-container-request';
import { maxLength } from 'vuelidate/lib/validators';

export default Vue.extend({
  name: 'ContainerDetailedViewDialog',
  components: {
    DetailedViewBase,
    Navigation,
    DocumentStoreDocuments,
    DocumentStoreComments,
    DocumentStoreChangelog,
    DocumentStoreBasicInfiormations,
    ActionButton,
  },
  props: {
    opened: { type: Boolean, default: false },
    container: { type: Object as () => DocumentContainer },
    requirementId: { type: Number },
  },
  data() {
    return {
      loading: false,
      editIcon: '/images/edit.svg',
      isEditing: false,
      submitted: false,
      editedTitle: this.container.title,
      activeComponent: 'DocumentStoreDocuments',
      menus,
    };
  },
  validations: {
    editedTitle: {
      maxLength: maxLength(50),
    },
  },
  computed: {
    containerTitleError(): string {
      return this.submitted && !(this.$v.editedTitle as any).maxLength
        ? this.$t('documents.container.title-max-length').toString()
        : '';
    },
  },
  methods: {
    closeDialog() {
      if (this.activeComponent === 'DocumentStoreDocuments') {
        (this.$refs.menu as any).resetFilter();
      }
      this.$emit('close');
    },
    onComponentChange(newComponent: string) {
      this.activeComponent = newComponent;
    },
    edit() {
      this.isEditing = true;
    },
    resetEdition() {
      this.isEditing = false;
      this.submitted = false;
      this.editedTitle = this.container.title;
    },
    saveEdited() {
      this.submitted = true;
      this.$v.editedTitle.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.$store
        .dispatch('documentContainerStorage/setContainerTitle', {
          containerId: this.container.id,
          request: new DocumentContainerRequest(this.editedTitle),
        })
        .finally(() => this.resetEdition());
    },
  },
});
