






import Vue from 'vue';
import ChangelogBase from '@/components/ChangelogBase.vue';
import DocumentContainer from '@/model/document-container';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'DocumentStoreChangelog',
  components: {
    ChangelogBase,
  },
  props: {
    container: { type: Object as () => DocumentContainer },
  },
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    this.$store
      .dispatch('changelogStorage/getDocumentContainerChangelogs', this.container.id)
      .finally(() => (this.loading = false));
  },
  computed: {
    ...mapGetters({ changelogs: 'changelogStorage/getDocumentContainerChangelogs' }),
  },
});
