
















import DocumentContainer from '@/model/document-container';
import Clock from '@/../public/images/document-clock.svg?inline';
import Vue from 'vue';

export default Vue.extend({
  name: 'DocumentContainerCard',
  components: {
    Clock,
  },
  props: {
    container: { type: Object as () => DocumentContainer },
  },
});
