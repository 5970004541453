





























import Vue, { PropType } from 'vue';
import DocumentContainerCard from '@/components/DocumentStore/DocumentContainerCard.vue';
import ContainerDetailedViewDialog from '@/components/DocumentStore/DetailedView/ContainerDetailedViewDialog.vue';
import RequirementRowBase from '@/components/ExpansionPanel/RequirementRowBase.vue';
import DocumentContainer from '@/model/document-container';
import RequirementDocument from '@/model/requirement-document';
import Documents from '@/../public/images/documents.svg?inline';

export default Vue.extend({
  name: 'DocumentRequirementRow',
  components: {
    DocumentContainerCard,
    ContainerDetailedViewDialog,
    Documents,
    RequirementRowBase,
  },
  props: {
    requirement: Object as () => RequirementDocument,
    removedBorder: { type: Boolean, default: false },
    years: { type: Array as () => PropType<number[]> },
    isMain: { type: Boolean, default: false },
  },
  data() {
    return {
      isDetailsOpen: false,
      openedContainer: {} as DocumentContainer,
    };
  },
  methods: {
    findContainer(year: number): DocumentContainer | undefined {
      return this.requirement?.documentContainers?.find((container: DocumentContainer) => container.year === year);
    },
    openContainerView(container: DocumentContainer) {
      this.openedContainer = container;
      this.isDetailsOpen = true;
    },
    closeDetailedView() {
      this.isDetailsOpen = false;
      this.openedContainer = {} as DocumentContainer;
    },
  },
});
