










import Vue from 'vue';
import BasicInformationCard from '@/components/DocumentStore/DetailedView/BasicInformation/BasicInformationCard.vue';
import People from '@/components/DocumentStore/DetailedView/BasicInformation/People/People.vue';
import Places from '@/components/DocumentStore/DetailedView/BasicInformation/Places/Places.vue';
import Tools from '@/components/DocumentStore/DetailedView/BasicInformation/Tools/Tools.vue';
import DocumentContainer from '@/model/document-container';

export default Vue.extend({
  name: 'DocumentStoreBasicInfiormations',
  components: {
    BasicInformationCard,
    People,
    Places,
    Tools,
  },
  props: {
    container: { type: Object as () => DocumentContainer },
    isEditing: { type: Boolean, default: false },
  },
});
