import { i18n } from '@/i18n';

export const menus = [
  {
    name: i18n.t('documents.detailed-view.menu.documents'),
    icon: '/images/documents.svg',
    componentName: 'DocumentStoreDocuments',
  },
  {
    name: i18n.t('documents.detailed-view.menu.basic-information'),
    icon: '/images/document-basic-info.svg',
    componentName: 'DocumentStoreBasicInfiormations',
  },
  {
    name: i18n.t('documents.detailed-view.menu.comments'),
    icon: '/images/comment.svg',
    componentName: 'DocumentStoreComments',
  },
  {
    name: i18n.t('documents.detailed-view.menu.history'),
    icon: '/images/history.svg',
    componentName: 'DocumentStoreChangelog',
  },
];
