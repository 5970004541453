



















import Vue from 'vue';
import TopMenu from '@/components/Menubar/TopMenu.vue';
import SearchBar from '@/components/Menubar/SearchBar.vue';
import DocumentHeader from '@/components/DocumentStore/DocumentHeader.vue';
import DocumentStore from '@/components/DocumentStore/DocumentStore.vue';
import { mapGetters } from 'vuex';
import PageOptions from '@/model/page/page-options';

export default Vue.extend({
  name: 'Documents',
  components: {
    TopMenu,
    SearchBar,
    DocumentHeader,
    DocumentStore,
  },
  computed: {
    ...mapGetters({
      documentSearchText: 'filterStorage/getDocumentSearchText',
    }),
  },
  data() {
    return {
      years: [] as number[],
    };
  },
  created() {
    let thisYear = new Date().getFullYear();
    for (thisYear; this.years.length < 7; thisYear++) {
      this.years.push(thisYear);
    }
  },
  methods: {
    textFiltering(text: string) {
      this.$store.commit('filterStorage/setDocumentSearchText', text);
      this.$store.commit('filterStorage/setFiltering', true);
      this.$store
        .dispatch('documentStorage/getRequirements', {
          pageOptions: new PageOptions(),
          category: '',
          years: this.years,
        })
        .finally(() => this.$store.commit('filterStorage/setFiltering', false));
    },
    decreaseYears() {
      this.years = this.years.map((year) => (year -= 1));
      this.getContainersOnYearPaging(this.years[0]);
    },
    increaseYears() {
      this.years = this.years.map((year) => (year += 1));
      this.getContainersOnYearPaging(this.years[this.years.length - 1]);
    },
    getContainersOnYearPaging(year: number) {
      this.$store.dispatch('documentContainerStorage/getContainersByYear', year);
    },
  },
});
