
















import Vue from 'vue';
export default Vue.extend({
  name: 'BasicInformationInfoPopup',
  props: {
    opened: { type: Boolean, default: false },
  },
});
