






































import Vue from 'vue';
import InfoButton from '@/components/Button/CircleButton.vue';
import Business from '@/model/business';
import Person from '@/model/person';
import Tool from '@/model/tool';

export default Vue.extend({
  name: 'BasicInformationCard',
  components: {
    InfoButton,
  },
  props: {
    title: { type: String, default: '' },
    items: { type: [Array as () => Person[], Array as () => Business[], Array as () => Tool[]] },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      plus: '/images/plus.svg',
      openedPopup: false,
    };
  },
});
