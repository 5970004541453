import { i18n } from '@/i18n';
import { GuestVisibility } from '@/model/enums/guest-visibility';

export const documentVisibility = [
  {
    title: i18n.t('documents.detailed-view.visibility-filter.all'),
    value: '',
  },
  {
    title: i18n.t('documents.detailed-view.visibility-filter.visible'),
    value: GuestVisibility.VISIBLE,
  },
  {
    title: i18n.t('documents.detailed-view.visibility-filter.not-visible'),
    value: GuestVisibility.NOT_VISIBLE,
  },
];
