




import Vue from 'vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import DeleteButton from '@/components/Button/CircleButton.vue';
import DocumentContainer from '@/model/document-container';
import Tool from '@/model/tool';
import Notification from '@/model/notification';

export default Vue.extend({
  name: 'DeletePerson',
  components: {
    LoadingIndicator,
    DeleteButton,
  },
  props: {
    container: { type: Object as () => DocumentContainer },
    item: { type: Object as () => Tool },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    removeTool() {
      this.$store
        .dispatch(
          'showConfirmNotification',
          new Notification(
            this.$t('basic-information.tool.delete', {
              name: this.item.name,
            }).toString(),
          ),
        )
        .then((confirmed) => {
          if (confirmed) {
            this.loading = true;
            this.$store
              .dispatch('documentContainerStorage/removeTool', {
                container: this.container,
                tool: this.item,
              })
              .finally(() => (this.loading = false));
          }
        });
    },
  },
});
