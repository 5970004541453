















































import Vue from 'vue';
import MainSectionPanel from '@/components/ExpansionPanel/MainSectionPanel.vue';
import MainRequirementPanel from '@/components/ExpansionPanel/MainRequirementPanel.vue';
import DocumentRequirementRow from '@/components/DocumentStore/RequirementRow.vue';
import { mapGetters } from 'vuex';
import PageOptions from '@/model/page/page-options';
import PageableRequirement from '@/model/page/pageable-requirement';
import Requirement from '@/model/requirement';

export default Vue.extend({
  name: 'DocumentContent',
  components: {
    MainSectionPanel,
    MainRequirementPanel,
    DocumentRequirementRow,
  },
  props: {
    years: { type: Array as () => number[] },
  },
  data() {
    return {
      loading: true,
      loadingMore: false,
    };
  },
  mounted() {
    this.$store
      .dispatch('documentStorage/getRequirements', {
        pageOptions: new PageOptions(),
        category: '',
        years: this.years,
      })
      .finally(() => (this.loading = false));
  },
  computed: {
    ...mapGetters(['getDocumentPanels', 'getDocumentSubPanels']),
    ...mapGetters({
      requirements: 'documentStorage/getRequirements',
    }),
  },
  methods: {
    loadMore(requirementList: PageableRequirement<Requirement>) {
      this.loadingMore = true;
      this.$store
        .dispatch('documentStorage/getRequirements', {
          pageOptions: new PageOptions(requirementList.currentPage + 1),
          category: requirementList.category,
          years: this.years,
        })
        .finally(() => (this.loadingMore = false));
    },
    onMainPanelChange(newPanels: number[]) {
      this.$store.commit('setDocumentPanels', newPanels, { root: true });
    },
    onSubPanelChange(newSubPanels: number[][]) {
      this.$store.commit('setDocumentSubPanels', newSubPanels, { root: true });
    },
  },
});
