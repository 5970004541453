






























































import Vue from 'vue';
import BasicInformationCard from '@/components/DocumentStore/DetailedView/BasicInformation/BasicInformationCard.vue';
import DeleteTool from '@/components/DocumentStore/DetailedView/BasicInformation/Tools/DeleteTool.vue';
import InfoPopop from '@/components/DocumentStore/DetailedView/BasicInformation/InfoPopop.vue';
import AutoComplete from '@/components/InputField/AutoComplete.vue';
import DocumentContainer from '@/model/document-container';
import Tool from '@/model/tool';

export default Vue.extend({
  name: 'DocumentStoreBasicInfiormationTools',
  components: {
    BasicInformationCard,
    DeleteTool,
    InfoPopop,
    AutoComplete,
  },
  props: {
    container: { type: Object as () => DocumentContainer },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      opened: false,
      openedItem: {} as Tool,
      itemsLoading: false,
      items: [] as Tool[],
    };
  },
  computed: {
    availableItems(): Tool[] {
      return this.container?.tools
        ? this.items.filter((item: Tool) => !this.container.tools.map((tool) => tool.id).includes(item.id))
        : this.items;
    },
  },
  methods: {
    openInfo(item: Tool) {
      this.openedItem = item;
      this.opened = true;
    },
    closeInfo() {
      this.opened = false;
      this.openedItem = {} as Tool;
    },
    loadTools() {
      this.itemsLoading = true;
      this.$store
        .dispatch('toolStorage/getAll')
        .then((data: Tool[]) => {
          this.items = data;
        })
        .finally(() => {
          this.itemsLoading = false;
          (this.$refs.autocomplete as any).clear();
          (this.$refs.autocomplete as any).focus();
        });
    },
    addTool(toolId: number) {
      if (toolId) {
        this.$store.dispatch('documentContainerStorage/addTool', { container: this.container, toolId }).finally(() => {
          (this.$refs.autocomplete as any).focus();
        });
      }
    },
  },
});
