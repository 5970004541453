


























































































import Vue from 'vue';
import BasicInformationCard from '@/components/DocumentStore/DetailedView/BasicInformation/BasicInformationCard.vue';
import DeletePerson from '@/components/DocumentStore/DetailedView/BasicInformation/People/DeletePerson.vue';
import InfoPopop from '@/components/DocumentStore/DetailedView/BasicInformation/InfoPopop.vue';
import AutoComplete from '@/components/InputField/AutoComplete.vue';
import DocumentContainer from '@/model/document-container';
import Person from '@/model/person';

export default Vue.extend({
  name: 'DocumentStoreBasicInfiormationPeople',
  components: {
    BasicInformationCard,
    DeletePerson,
    InfoPopop,
    AutoComplete,
  },
  props: {
    container: { type: Object as () => DocumentContainer },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      opened: false,
      openedItem: {} as Person,
      itemsLoading: false,
      items: [] as Person[],
    };
  },
  computed: {
    availableItems(): Person[] {
      return this.container?.people
        ? this.items.filter((item: Person) => !this.container.people.map((person) => person.id).includes(item.id))
        : this.items;
    },
  },
  methods: {
    openInfo(item: Person) {
      this.openedItem = item;
      this.opened = true;
    },
    closeInfo() {
      this.opened = false;
      this.openedItem = {} as Person;
    },
    loadPeople() {
      this.itemsLoading = true;
      this.$store
        .dispatch('personStorage/getAll')
        .then((data: Person[]) => {
          this.items = data;
        })
        .finally(() => {
          this.itemsLoading = false;
          (this.$refs.autocomplete as any).clear();
          (this.$refs.autocomplete as any).focus();
        });
    },
    addPerson(personId: number) {
      if (personId) {
        this.$store
          .dispatch('documentContainerStorage/addPerson', { container: this.container, personId })
          .finally(() => {
            (this.$refs.autocomplete as any).focus();
          });
      }
    },
  },
});
