




























import Vue from 'vue';
import HeaderBase from '@/components/Header/HeaderBase.vue';

export default Vue.extend({
  name: 'DocumentHeader',
  components: {
    HeaderBase,
  },
  props: {
    years: { type: Array as () => number[] },
  },
});
