import { GuestVisibility } from '@/model/enums/guest-visibility';

export default class DocumentFilterOptions {
  public inherited: boolean;
  public containerId: number;
  public requirementId: number;
  public text?: string;
  public startDate: string;
  public endDate: string;
  public visibility: GuestVisibility | string;

  constructor(
    inherited: boolean,
    containerId: number,
    requirementId: number,
    text?: string,
    startDate: string = '',
    endDate: string = '',
    visibility: GuestVisibility | string = '',
  ) {
    this.inherited = inherited;
    this.containerId = containerId;
    this.requirementId = requirementId;
    this.text = text;
    this.startDate = startDate;
    this.endDate = endDate;
    this.visibility = visibility;
  }
}
