













import Vue from 'vue';
import CommentBase from '@/components/Comment/CommentBase.vue';
import DocumentContainer from '@/model/document-container';
import { mapGetters } from 'vuex';
import CommentRequest from '@/model/request/comment-request';

export default Vue.extend({
  name: 'DocumentStoreComments',
  components: {
    CommentBase,
  },
  props: {
    container: { type: Object as () => DocumentContainer },
    isEditing: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    this.$store
      .dispatch('commentStorage/getDocumentContainerComments', this.container.id)
      .finally(() => (this.loading = false));
  },
  computed: {
    ...mapGetters({ comments: 'commentStorage/getDocumentContainerComments' }),
  },
  methods: {
    sendComment(comment: string) {
      this.$store
        .dispatch('commentStorage/sendDocumentContainerComment', {
          containerId: this.container.id,
          commentRequest: new CommentRequest(comment),
        })
        .then((data) => {
          if (data) {
            this.reset();
          }
        })
        .finally(() => (this.$refs.commentInput as any).resetSending());
    },
    reset() {
      (this.$refs.commentInput as any).reset();
    },
  },
});
