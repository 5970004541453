







import Vue from 'vue';

export default Vue.extend({
  name: 'ActionButton',
  props: {
    iconType: {
      validator: (value: string) => ['close', 'delete', 'edit', 'undo', 'inactivate', 'save', 'add'].includes(value),
      required: true,
    },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      closeIcon: '/images/detailed-view-close.svg',
      editIcon: '/images/edit.svg',
      deleteIcon: '/images/bin.svg',
      addIcon: '/images/add.svg',
    };
  },
  computed: {
    hasImage(): boolean {
      return ['close', 'edit', 'delete', 'add'].includes(this.iconType);
    },
    iconImage(): string {
      let image = '';
      switch (this.iconType) {
        case 'close':
          image = this.closeIcon;
          break;
        case 'delete':
          image = this.deleteIcon;
          break;
        case 'edit':
          image = this.editIcon;
          break;
        case 'add':
          image = this.addIcon;
          break;
      }
      return image;
    },
    icon(): string {
      let icon = '';
      switch (this.iconType) {
        case 'undo':
          icon = 'fas fa-undo';
          break;
        case 'inactivate':
          icon = 'fas fa-ban';
          break;
        case 'save':
          icon = 'fas fa-check';
          break;
      }
      return icon;
    },
  },
});
