






















import { DocumentExpiry } from '@/model/enums/document-expiry';
import { GuestVisibility } from '@/model/enums/guest-visibility';
import Option from '@/model/option';
import Vue from 'vue';

export default Vue.extend({
  name: 'DocumentFilter',
  props: {
    options: { type: Array as () => Option[] },
    selected: { type: String as () => DocumentExpiry | GuestVisibility, default: '' },
    isEditing: { type: Boolean, default: false },
  },
  data() {
    return {
      selectedOption: this.selected,
    };
  },
  updated() {
    this.selectedOption = this.selected;
  },
  methods: {
    selectOption(selected: DocumentExpiry | GuestVisibility) {
      if (this.selectedOption !== selected) {
        this.selectedOption = selected;
      } else {
        this.reset();
      }
      this.$emit('filter', this.selectedOption);
    },
    reset() {
      this.selectedOption = '' as DocumentExpiry | GuestVisibility;
    },
  },
});
