










import Vue from 'vue';
import Checkbox from '@/components/InputField/Checkbox.vue';
import Notification from '@/model/notification';
import DocumentStoreDocument from '@/model/document-store-document';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'Visibility',
  components: {
    Checkbox,
  },
  props: {
    document: Object as () => DocumentStoreDocument,
    isEditing: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters({ isAdmin: 'currentUserStorage/isAdmin' }),
  },
  methods: {
    confirm(document: DocumentStoreDocument) {
      this.$store
        .dispatch(
          'showConfirmNotification',
          new Notification(
            this.$t(
              document.visible
                ? 'documents.detailed-view.visibility.confirmation-withdraw'
                : 'documents.detailed-view.visibility.confirmation',
              { title: document.file.originalName },
            ).toString(),
          ),
        )
        .then((confirmed) => {
          if (confirmed) {
            (this.$refs[`checkbox${document.id}`] as any).setLoading(true);
            this.$store.dispatch('documentContainerStorage/setVisibility', document.id).finally(() => {
              (this.$refs[`checkbox${document.id}`] as any).setLoading(false);
            });
          }
        });
    },
  },
});
