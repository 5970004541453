





























































import Vue from 'vue';
import BasicInformationCard from '@/components/DocumentStore/DetailedView/BasicInformation/BasicInformationCard.vue';
import DeletePlace from '@/components/DocumentStore/DetailedView/BasicInformation/Places/DeletePlace.vue';
import InfoPopop from '@/components/DocumentStore/DetailedView/BasicInformation/InfoPopop.vue';
import AutoComplete from '@/components/InputField/AutoComplete.vue';
import DocumentContainer from '@/model/document-container';
import Business from '@/model/business';

export default Vue.extend({
  name: 'DocumentStoreBasicInfiormationPlaces',
  components: {
    BasicInformationCard,
    DeletePlace,
    InfoPopop,
    AutoComplete,
  },
  props: {
    container: { type: Object as () => DocumentContainer },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      opened: false,
      openedItem: {} as Business,
      itemsLoading: false,
      items: [] as Business[],
    };
  },
  computed: {
    availableItems(): Business[] {
      return this.container?.places
        ? this.items.filter((item: Business) => !this.container.places.map((tool) => tool.id).includes(item.id))
        : this.items;
    },
  },
  methods: {
    openInfo(item: Business) {
      this.openedItem = item;
      this.opened = true;
    },
    closeInfo() {
      this.opened = false;
      this.openedItem = {} as Business;
    },
    loadPlaces() {
      this.itemsLoading = true;
      this.$store
        .dispatch('businessStorage/getBusinesses')
        .then((data: Business[]) => {
          this.items = data;
        })
        .finally(() => {
          this.itemsLoading = false;
          (this.$refs.autocomplete as any).focus();
        });
    },
    addPlace(placeId: number) {
      if (placeId) {
        this.$store
          .dispatch('documentContainerStorage/addPlace', { container: this.container, placeId })
          .finally(() => {
            (this.$refs.autocomplete as any).clear();
            (this.$refs.autocomplete as any).focus();
          });
      }
    },
  },
});
