



























































































import Vue from 'vue';
import Calendar from '@/../public/images/clock-calendar.svg?inline';
import Table from '@/components/Table/Table.vue';
import Checkbox from '@/components/InputField/Checkbox.vue';
import SearchBar from '@/components/Menubar/SearchBar.vue';
import DocumentFilter from '@/components/DocumentStore/DetailedView/Filter/DocumentFilter.vue';
import DatePicker from '@/components/InputField/DatePicker.vue';
import Visibility from '@/components/DocumentStore/DetailedView/Visibility.vue';
import moment from 'moment';
import { FILTER_DATE_FORMAT } from '@/constants/date-format';
import { documentExpiry } from '@/constants/filter/document-expiry';
import DocumentFilterOptions from '@/model/filter/document-filter-options';
import { documentVisibility } from '@/constants/filter/document-visibility';
import { mapGetters } from 'vuex';
import { GuestVisibility } from '@/model/enums/guest-visibility';
import { DocumentExpiry } from '@/model/enums/document-expiry';
import PageOptions from '@/model/page/page-options';
import DocumentContainer from '@/model/document-container';

export default Vue.extend({
  name: 'DocumentStoreDocuments',
  components: {
    Calendar,
    Table,
    Checkbox,
    SearchBar,
    DocumentFilter,
    DatePicker,
    Visibility,
  },
  props: {
    container: { type: Object as () => DocumentContainer },
    requirementId: { type: Number },
    isEditing: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters({
      documents: 'documentContainerStorage/getContainerDocuments',
      isGuest: 'currentUserStorage/isGuest',
    }),
    selectedFields(): object[] {
      if (this.isGuest) {
        this.tableFields.pop();
      }
      return this.tableFields;
    },
    expirySelected(): boolean {
      return this.filterOptions?.startDate?.length > 0 || this.filterOptions?.endDate?.length > 0;
    },
    visibilitySelected(): boolean {
      return this.filterOptions?.visibility?.length > 0;
    },
  },
  mounted() {
    this.fetch();
  },
  data() {
    return {
      searchIcon: '/images/search.svg',
      loading: false,
      documentExpiry,
      documentVisibility,
      filterOptions: new DocumentFilterOptions(this.container.inherited, this.container.id, this.requirementId),
      selectedExpiry: '' as DocumentExpiry,
      tableFields: [
        {
          text: this.$t('documents.detailed-view.document-name'),
          key: 'name',
          label: this.$t('documents.detailed-view.document-name'),
          thStyle: 'width: 30%',
        },
        {
          text: this.$t('documents.detailed-view.expiry'),
          key: 'expiryDate',
          label: this.$t('documents.detailed-view.expiry'),
          thStyle: 'width: 20%',
        },
        {
          text: this.$t('documents.detailed-view.validity'),
          key: 'validity',
          label: this.$t('documents.detailed-view.validity'),
          thStyle: 'width: 20%',
        },
        {
          text: this.$t('documents.detailed-view.author'),
          key: 'author',
          label: this.$t('documents.detailed-view.author'),
          thStyle: 'width: 20%',
        },
        {
          text: this.$t('documents.detailed-view.visibility.title'),
          key: 'visibility',
          label: this.$t('documents.detailed-view.visibility.title'),
          thStyle: 'width: 10%',
        },
      ],
    };
  },
  methods: {
    fetch() {
      this.loading = true;
      this.$store
        .dispatch('documentContainerStorage/getPageableDocuments', {
          pageOptions: new PageOptions(0, 25),
          filterOptions: this.filterOptions,
        })
        .finally(() => (this.loading = false));
    },
    textFiltering(searchText: string) {
      this.filterOptions.text = searchText;
      this.fetch();
    },
    dateFiltering(selected: DocumentExpiry) {
      const today = new Date();
      this.selectedExpiry = selected;
      switch (selected) {
        case DocumentExpiry.THIS_MONTH:
          this.filterOptions.startDate = this.calcuateDate(today.getFullYear(), today.getMonth(), true);
          this.filterOptions.endDate = this.calcuateDate(today.getFullYear(), today.getMonth() + 1, false);
          break;
        case DocumentExpiry.NEXT_THREE_MONTHS:
          this.filterOptions.startDate = this.calcuateDate(today.getFullYear(), today.getMonth() + 1, true);
          this.filterOptions.endDate = this.calcuateDate(today.getFullYear(), today.getMonth() + 4, false);
          break;
        case DocumentExpiry.THIS_YEAR:
          this.filterOptions.startDate = this.calcuateDate(today.getFullYear(), 0, true);
          this.filterOptions.endDate = this.calcuateDate(today.getFullYear() + 1, 0, false);
          break;
        case DocumentExpiry.NEXT_THREE_YEARS:
          this.filterOptions.startDate = this.calcuateDate(today.getFullYear() + 1, 0, true);
          this.filterOptions.endDate = this.calcuateDate(today.getFullYear() + 4, 0, false);
          break;
        default:
          this.filterOptions.startDate = '';
          this.filterOptions.endDate = '';
      }
      this.fetch();
    },
    calcuateDate(year: number, month: number, isStart: boolean): string {
      return moment(new Date(year, month, isStart ? 1 : 0)).format(FILTER_DATE_FORMAT);
    },
    calculateValidity(expiry: string) {
      let expiryPeriod = '-';
      if (expiry) {
        const expiryDate = new Date(expiry);
        const days = (expiryDate.getTime() - new Date().getTime()) / (1000 * 3600 * 24);
        if (days < 0) {
          expiryPeriod = this.$t('documents.detailed-view.expiry-options.expired').toString();
        } else if (days < 7) {
          expiryPeriod = `${Math.round(days)} ${this.$t('documents.detailed-view.expiry-options.day')}`;
        } else if (days < 365) {
          const months = Math.floor(days / 30);
          expiryPeriod = `${months > 0 ? `${months} ${this.$t('documents.detailed-view.expiry-options.month')}` : ''} ${
            days > months * 30
              ? `${Math.floor(days - months * 30)} ${this.$t('documents.detailed-view.expiry-options.day')}`
              : ''
          }`;
        } else {
          const years = Math.round(days / 365);
          expiryPeriod = `${years}${this.$t('documents.detailed-view.expiry-options.year')} ${
            days > years * 365
              ? `${Math.floor(days - years * 365)} ${this.$t('documents.detailed-view.expiry-options.day')}`
              : ''
          }`;
        }
      }
      return expiryPeriod;
    },
    onStartChange(newDate: string) {
      this.onDateChange(newDate, true);
    },
    onEndChange(newDate: string) {
      this.onDateChange(newDate, false);
    },
    onDateChange(newDate: string, isStart: boolean) {
      isStart ? (this.filterOptions.startDate = newDate) : (this.filterOptions.endDate = newDate);
      this.selectedExpiry = '' as DocumentExpiry;
      this.filterOptions = { ...this.filterOptions };
      this.fetch();
    },
    visibilityFiltering(selected: GuestVisibility) {
      this.filterOptions.visibility = selected;
      this.filterOptions = { ...this.filterOptions };
      this.fetch();
    },
    resetFilter() {
      this.selectedExpiry = '' as DocumentExpiry;
      this.filterOptions = {
        ...new DocumentFilterOptions(this.container.inherited, this.container.id, this.requirementId),
      };
      (this.$refs.textFilter as any).resetText();
      (this.$refs.expiryFilter as any).reset();
      (this.$refs.visibilityFilter as any).reset();
    },
  },
});
